import { resources } from 'pixi.js'

// Inspired by https://www.html5gamedevs.com/topic/42086-using-webcam-with-pixijs-v4/

/**
 * Resource type for HTMLVideoElement.
 * @class
 * @extends PIXI.resources.VideoResource
 * @memberof PIXI.resources
 * @param {MediaStream} source - Media stream to use
 * @param {object} [options] - Options to use
 * @param {number} [options.updateFPS=0] - How many times a second to update the texture from the video.
 * Leave at 0 to update at every render.
 */
export default class MediaStreamResource extends resources.VideoResource {
  constructor(source, options) {
    options = options || {}

    const videoElement = document.createElement('video')

    videoElement.srcObject = source
    // videoElement.play()

    super(videoElement, {
      autoPlay: true,
      autoLoad: true,
      ...options.updateFPS
    })
  }
}
