import { each, isNumber } from 'lodash'

const MIN_DELTA = 0.00001
const DEFAULT_GAIN = 0.02

// Ease a single value towards another value by some gain percentage
function ease(val, to, gain) {
  let d = to - val
  if (d == 0 || Math.abs(d) < MIN_DELTA) {
    return to
  } else {
    return val + d * gain
  }
}

// Ease all the numeric properties of an object to the
// value of the same property on a target object
function easeObject(obj, targetObj, gain = DEFAULT_GAIN) {
  gain = gain || 0.02
  each(obj, (val, prop) => {
    if (isNumber(val)) {
      obj[prop] = ease(val, targetObj[prop], gain)
    } else {
      obj[prop] = targetObj[prop]
    }
  })
}

ease.object = easeObject

export default ease
export { easeObject }
