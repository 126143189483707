export const onVideoEnd = () => {
  window.parent.postMessage({ eventName: "videoEnd" }, "*");
};

let endHandled = false;

export const handleTimeRemaining = (timeRemaining) => {
  if (timeRemaining === 0 && !endHandled) {
    endHandled = true;
    onVideoEnd();
  }
};
