const DEVICE_ID = 'DEVICE_ID'

export class DeviceSwitcher extends EventTarget {
  constructor(onError) {
    super()
    this.videoDevices = []
    this.currentDevice = null
    this.currentDeviceIndex = null
    this.playFallbackVideo = onError
    navigator.mediaDevices
      .enumerateDevices()
      .then((params) => this.gotDevices(params))
      .catch(this.handleError)
  }

  gotDevices(deviceInfos) {
    this.videoDevices = deviceInfos.filter((deviceInfo) => deviceInfo.kind === 'videoinput')
    console.log('devices.length', this.videoDevices.length);
    if (this.videoDevices.length > 0) {
      this.initPreviouslyUsedDevice()
      this.dispatchEvent(new Event('change'))
    }
    if (this.videoDevices.length > 1) {
      this.renderButton()
    }

    if (this.videoDevices.length === 0) {
      this.playFallbackVideo(new Error('No cameras found'))
    }
    return this.videoDevices
  }

  useNextDevice() {
    this.currentDeviceIndex = (this.currentDeviceIndex + 1) % this.videoDevices.length
    this.currentDevice = this.videoDevices[this.currentDeviceIndex]

    localStorage.setItem(DEVICE_ID, this.currentDevice.deviceId)
    this.dispatchEvent(new Event('change'))
  }

  initPreviouslyUsedDevice() {
    const prevDeviceId = localStorage.getItem(DEVICE_ID)
    const foundIndex = this.videoDevices.findIndex(
      (device) => device.deviceId === prevDeviceId
    )

    this.currentDeviceIndex = foundIndex > -1 ? foundIndex : 0
    this.currentDevice = this.videoDevices[this.currentDeviceIndex]
  }

  renderButton() {
    this.buttonElement = document.createElement('button')
    this.buttonElement.classList.add('deviceSwitcher')
    this.buttonElement.innerText = ''
    this.buttonElement.addEventListener('click', () => {
      this.useNextDevice()
    })
    document.querySelector('body').appendChild(this.buttonElement)
  }

  handleError(error) {
    console.log('DeviceSwitcher.handleError error: ', error.message, error.name)
    this.playFallbackVideo(error)
  }
}
