// records wrist positions

// Keep buffer (last X recordings)

// calculates sum, avg (mean), and STD of collected values

const buffers = {}
// const BUFFER_LENGTH = 4 // increase for more smoothing (less reactivity)

const tracker = {
  // data will arrive here
  stats: {},

  maxBufferLength: 4, // increase for more smoothing (less reactivity)

  log(key, data) {
    const buffer = buffers[key] || (buffers[key] = [])
    if (buffer.length > this.maxBufferLength) buffer.shift()
    buffer.push({
      ...data
      // __t: +new Date()
    })
    //setTimeout(() => {
    this.updateStats(key)
    //}, 0)
  },

  // key == part name (ex: leftWrist)
  updateStats(key) {
    ;['x', 'y', 'score', 'velocity'].forEach((prop) => {
      const partStats = this.stats[key] || (this.stats[key] = {})
      const propStats = partStats[prop] || (partStats[prop] = {})

      // 'pick' array of property values from array of data objects
      const values = buffers[key].map((d) => d[prop])

      propStats.len = values.length
      propStats.sum = values.reduce((prev, curr) => prev + curr, 0)
      propStats.avg = propStats.sum / propStats.len
      propStats.std = Math.sqrt(
        values.map((x) => Math.pow(x - propStats.avg, 2)).reduce((a, b) => a + b) / propStats.len
      )
      // if (key == 'nose' && prop == 'velocity') {
      //   debugger
      // }

      // TODO: Consider a weighted-mean using the score as the weight
      // https://gist.github.com/stekhn/a12ed417e91f90ecec14bcfa4c2ae16a
    })
  }
}

const weightedMean = (arrValues = [], arrWeights = []) => {
  const result = arrValues
    .map((value, i) => {
      const weight = arrWeights[i]
      const sum = value * weight
      return [sum, weight]
    })
    .reduce((p, c) => [p[0] + c[0], p[1] + c[1]], [0, 0])

  return result[0] / result[1]
}


export default tracker

if (window) window._trackerStats = tracker.stats
