import { Howl } from 'howler'

import { easeObject } from './util/ease'

// Interactive soundscape manager
// Loads and plays sounds

export default (settings) => {
  // console.log('load soundscape', settings)

  // Init Sounds

  // const sound = new Howl({
  //   src: ['/sounds/waves.mp3'],
  //   autoplay: false,
  //   volume: 0,
  //   sprite: {
  //     wave1: [1000, 11000, true], // true == looping
  //     wave2: [12000, 21000, true],
  //     wave3: [23000, 31000, true],
  //     wave4: [34000, 41000, true]
  //   }
  // })

  const sounds =
    settings &&
    settings.map((s) => {
      const howl = new Howl({
        src: [s.file],
        autoplay: true,
        loop: true,
        volume: 0
      })
      return {
        ...s,
        howl
      }
    })

  // // play the clips
  // const wave1 = sound.play('wave1')
  // const wave2 = sound.play('wave2')
  // const wave3 = sound.play('wave3')
  // const wave4 = sound.play('wave4')

  // stereo pan settings for left and right sounds
  // sound.stereo(-1, wave1)
  // sound.stereo(1, wave2)
  // sound.stereo(-1, wave3)
  // sound.stereo(1, wave4)

  // // Spatial audio placement is also an option
  // sound.pos(-1, 0, 0, wave1)
  // sound.pos(1, 0, 0, wave2)

  // our eased parameters
  const state = {
    activityLeft: 0.01,
    activityRight: 0.01,
    activityHead: 0.01,
    xPosLeft: 0.01,
    yPosLeft: 0.99,
    xPosRight: 0.01,
    yPosRight: 0.99,
    velocityLeft: 0.01,
    velocityRight: 0.01,
    velocityHead: 0.01
  }

  // setup targetState for easing
  let targetState = { ...state }
  let _stopping = false

  const loop = () => {
    if (!sounds) return

    const { maxDistance, wristVelocityCurve, headVelocityCurve } = self

    // const minDistance = 16
    const panSpread = maxDistance * 2

    sounds.forEach((s) => {
      switch (s.trigger) {
        case 'activityHead':
          s.howl.volume(state.activityHead)
          break

        case 'velocityHead':
          s.howl.volume(Math.pow(state.velocityHead, headVelocityCurve))
          break

        case 'activityLeft':
          s.howl.volume(state.activityLeft)
          s.howl.pos(-maxDistance + state.xPosLeft * panSpread, 0, 0)
          break

        case 'activityRight':
          s.howl.volume(state.activityRight)
          s.howl.pos(-maxDistance + state.xPosRight * panSpread, 0, 0)
          break

        case 'velocityLeft':
          s.howl.volume(Math.pow(state.velocityLeft, wristVelocityCurve))
          s.howl.pos(-maxDistance + state.xPosLeft * panSpread, 0, 0)
          break

        case 'velocityRight':
          s.howl.volume(Math.pow(state.velocityRight, wristVelocityCurve))
          s.howl.pos(-maxDistance + state.xPosRight * panSpread, 0, 0)
          break
      }
    })

    // sound.volume(state.activityLeft, wave3)
    // sound.volume(state.activityLeft, wave4)

    // ease current state towards targetState
    easeObject(state, targetState, self.easingFactor)

    // keep going unless it's time to stop
    if (!_stopping) requestAnimationFrame(loop)
  }

  const self = {
    state,

    // non-eased config settings
    easingFactor: 0.15,
    maxDistance: 16,
    wristVelocityCurve: 1,
    headVelocityCurve: 1,

    // set a properties (activityLeft, activityRight, etc)
    set(props) {
      targetState = { ...targetState, ...props }
    },

    // current state
    get() {
      return state
    },

    stop() {
      _stopping = true
    },

    start() {
      _stopping = false
      loop()
    }
  }

  return self
}
