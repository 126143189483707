import sanityClient from '@sanity/client'
import { getFile } from '@sanity/asset-utils'

const SANITY_CONFIG = {
  projectId: process.env.SANITY_PROJECT_ID,
  dataset: process.env.SANITY_DATASET
}

const SANITY_ASSET_DOMAIN = process.env.SANITY_ASSET_DOMAIN
const PRODUCTION_ASSET_DOMAIN = process.env.PRODUCTION_ASSET_DOMAIN

console.log("process.env.PRODUCTION_ASSET_DOMAIN", process.env.PRODUCTION_ASSET_DOMAIN)

const client = sanityClient({
  ...SANITY_CONFIG,

  // Using API CDN is cheaper. Using API directly ensures latest data.
  useCdn: process.env.NODE_ENV !== 'development'
})

export default {
  async getSceneForPath(path) {
    // query for the first scene with the slug matching this path (ex: joyful)
    const query = '*[_type=="scene" && slug.current == $path]{ ..., soundscape-> }[0]'
    const params = { path } // passing in path as a parameter ($path)

    // Look up with sanity client
    return client.fetch(query, params).then((scene) => {
      if (scene === null) {
        console.log('Scene not found.', path)
      } else {
        return parseSceneSettings(scene)
      }
    })
  }
}

export const parseSceneSettings = (scene) => {
  const settings = {
    ...scene,
    bodyColors: convertColors(scene.bodyColors),
    fillColors: convertColors(scene.fillColors),
    // backgroundColors: convertColors(scene.backgroundColors),
    curtainColor: scene.curtainColor
      ? convertColor(scene.curtainColor)
      : convertColor([...scene.fillColors.colors].pop()),
    backgroundVideo: convertFileRef(scene.backgroundVideo),
    fillVideo: convertFileRef(scene.fillVideo),
    mobileVideo: convertFileRef(scene.mobileVideo),
    guideVideo: convertFileRef(scene.guideVideo),
    sounds:
      scene.soundscape &&
      scene.soundscape.sounds &&
      scene.soundscape.sounds.map((s) => ({ ...s, file: convertFileRef(s.file) }))
  }
  return settings
}

// convert sanity gradients to RGB arrays
const convertColors = (field) => (field && field.colors.map(convertColor)) || []
const convertColor = ({ rgb: { r, g, b } }) => [r, g, b]

// convert sanity file references to URLs
const convertFileRef = (file) => {
  try {
    const {
      asset: { url }
    } = getFile(file.asset, SANITY_CONFIG)
    return url.split(SANITY_ASSET_DOMAIN).join(PRODUCTION_ASSET_DOMAIN)
  } catch (e) {
    return null
  }
}
