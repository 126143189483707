// some interpolation utils
export const interp = (pos, start, end) => start + (end - start) * pos

// color interpolation
export const interpColor = (pos, colors) => {
  const n = colors.length
  const ipos = pos * n
  const s = Math.floor(ipos)
  const rpos = ipos - s

  // start and end color
  const c1 = colors[s % n]
  const c2 = colors[(s + 1) % n]

  // compute RGB channels
  const r = interp(rpos, c1[0], c2[0]) >> 0
  const g = interp(rpos, c1[1], c2[1]) >> 0
  const b = interp(rpos, c1[2], c2[2]) >> 0

  return [r, g, b]
}
