import { ApplicationInsights } from "@microsoft/applicationinsights-web";

console.log('BRANCH', process.env.BRANCH)
const instrumentationKey =
  process.env.BRANCH === 'production'
    ? process.env.APPINSIGHTS_INSTRUMENTATIONKEY
    : process.env.DEV_APPINSIGHTS_INSTRUMENTATIONKEY


const urlParams = new URLSearchParams(window.location.search);
const tenant = urlParams.get('tenant');

export const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey
  },
})

appInsights.addTelemetryInitializer((item) => {
  if (item.data) {
    if (tenant) {
      item.data.tenant = tenant;
    }
  }
})

appInsights.loadAppInsights()
appInsights.trackPageView()

